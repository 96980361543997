<template lang="pug">
.step-subtitle-container(v-if="vehicleColor")
  span
    div.vehicle-color-name {{ vehicleColor.modelColor ? vehicleColor.modelColor.name : '' }}
    div.vehicle-color-cost &nbsp;+&nbsp;
      span {{ formatPrice(vehicleColor.calculated_price) }}
      | &nbsp;{{ currencySymbol }}/{{ $t('mon') }}
    div(v-if="hasDeliveryTime")
      span.vehicle-delivery-time {{ deliveryTimeText }} &nbsp;
      span(v-if="vehicleColor.delivery_days_from !== null && vehicleColor.delivery_days_to !== null")
        | {{getDays(vehicleColor.delivery_days_from, false)}} - {{getDays(vehicleColor.delivery_days_to, 'short')}}
      span(v-else)
        | {{ deliveryDaysCountFrom.number }}
        | {{ deliveryDaysCountFrom.text.trim() === deliveryDaysCountTo.text.trim() ? '' : deliveryDaysCountFrom.text }} -
        | {{ deliveryDaysCountTo.number }}
        | {{ deliveryDaysCountTo.text }}
    small.text-danger {{ errors.first('vehicleColor:required') }}
      .color-check
        template(v-for="(color, index) in vehicleColors")
          input.color-check-input(
            type="radio"
            data-vv-name="vehicleColor"
            v-model="vehicleColorId"
            v-validate="'required'"
            :checked="vehicleColorId === color.id"
            :id="`radioColor${index}`"
            :value="color.id"
            :title="color.modelColor.name"
            @change="setVehicleColor(color)"
          )
          label.color-check-item(
            :for="`radioColor${index}`"
            :title="color.modelColor.name"
          )
            .model-color(
              :class="{'model-color-met' : color.modelColor.metallic, 'model-color-bordered': color.modelColor.hex.toUpperCase() === '#FFFFFF'}"
              :style="getColorStyle(color.modelColor)"
            )
              i.fas.fa-check

</template>

<script>
import {formatPrice, getDaysOrWeeks} from '../../../helpers/helpers.js'
import {mapMutations, mapState} from "vuex";
import ProductStepMixin from '@/mixins/ProductStepMixin'

export default {
  name: "VehicleColor",
  mixins: [ ProductStepMixin, ],
  methods: {
    ...mapMutations('product', [
      'setVehicleColor',
    ]),
    formatPrice,
    getDays(days, flag) {
      const daysParam = getDaysOrWeeks(days, flag, this.market.lang);

      return `${daysParam.number} ${daysParam.text}`;
    },
    isColor(strColor){
      const s = new Option().style;
      s.color = strColor;
      return s.color == strColor;
    },
    getColorStyle(colorData) {
      return colorData.secondary_hex ? `background: linear-gradient(to right, ${colorData.hex} 50%, ${colorData.secondary_hex} 50% )` : `background: ${colorData.hex}`
    }
  },
  watch: {
    vehicleColor: function (n) {
      if ((n && !!this.vehicleColorId === false) || this.vehicleColorId === undefined) {
        this.vehicleColorId = n.id
      }
    },
    vehicleColorId: function (n) {
      if (n) {
        if (this.vehicleColors) {
          this.vehicleColor = this.vehicleColors.find(item => item.id === n) || {}
        }

        this.$store.dispatch('order/saveAllFields', {'vehicleColorId': n})
      }
    },
    vehicleColors: {
      handler: function (colors) {
        let newVehicleColor = colors.find(item => item.id === this.vehicleColorId)

        if (!newVehicleColor) {
          newVehicleColor = colors.find(item => item.default)
        }

        this.vehicleColor = newVehicleColor || colors[0] || {}
      },
      deep: true
    },
  },
  computed: {
    ...mapState('reseller', {
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
      hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
      deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
      market: state => state.resellerInfo?.market || {},
    }),
    vehicleColorId: {
      get() {
        return this.$store.state.order.vehicleColorId;
      },
      set(value) {
        this.$store.commit('order/setVehicleColorId', value)
      }
    },
  },
}
</script>

<style scoped>

</style>